export default defineNuxtPlugin({
  name: 'a2s',
  async setup(nuxtApp) {
    const { $device } = nuxtApp as any

    const sendLog = (object = '', option = {}, type = 'click') => {
      const isMobile = $device.isMobileOrTablet
      let mobileOS = ''
      if ($device.isAndroid) mobileOS = 'Android'
      if ($device.isIos) mobileOS = 'iOS'
      try {
        const newOption = {
          ...option,
          ...(isMobile && mobileOS && { OS: mobileOS }),
          gamecode: 'HelloMaple',
          platformtype: isMobile ? 'Mobile' : 'PC'
        }

        const { $h } = window
        if ($h && typeof $h.a2s !== 'undefined') {
          if (type === 'click') {
            $h.a2s.sendClickLog(object, JSON.stringify(newOption))
          } else if (type === 'contents') {
            $h.a2s.sendContentsLog(object, JSON.stringify(newOption))
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
    const sendLogFromElement = (element: HTMLElement, type: string) => {
      const dataset = element.dataset
      const { a2sObjName } = dataset
      const option = {}
      if (!a2sObjName) return

      Object.entries(dataset).forEach((dataEntry) => {
        const [key = '', value = ''] = dataEntry
        if (!key.includes('a2s')) return

        if (key.includes('a2sOption')) {
          const a2sObjectKey = key.replace('a2sOption', '')
          Object.assign(option, { [a2sObjectKey]: value })
        }
      })
      sendLog(a2sObjName, option, type)
    }

    return {
      provide: {
        sendLog,
        sendLogFromElement
      }
    }
  }
})
