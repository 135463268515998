import { REFRESH_TOKEN_KEY, ACCESS_TOKEN_KEY } from '@/utils/constants'

export function useAuthToken() {
  // accessToken
  const _at = useCookie<string | null>(ACCESS_TOKEN_KEY, {
    path: '/',
    maxAge: 900,
    default: () => null
  })

  const lastSetTime = useCookie<string | null>('_l_t', {
    path: '/',
    maxAge: 900,
    default: () => null
  })

  // refreshToken
  const _rt = useCookie<string | null>(REFRESH_TOKEN_KEY, {
    path: '/',
    maxAge: 21600,
    default: () => null
  })

  const accessToken = useState(ACCESS_TOKEN_KEY, () => _at.value)
  const refreshToken = useState(REFRESH_TOKEN_KEY, () => _rt.value)
  watch(
    () => accessToken.value,
    (newVal) => {
      _at.value = newVal
      lastSetTime.value = newVal ? `${new Date().getTime()}` : null
    }
  )
  watch(
    () => refreshToken.value,
    (newVal) => {
      _rt.value = newVal
    }
  )

  return { accessToken, refreshToken, lastSetTime }
}
