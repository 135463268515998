import { default as download0ovpAXlwqnMeta } from "/app/pages/download.vue?macro=true";
import { default as education_45resourcesPlIHN1aw1jMeta } from "/app/pages/education-resources.vue?macro=true";
import { default as faqIMkmXQpKKaMeta } from "/app/pages/faq.vue?macro=true";
import { default as gateLrWVWI1DmOMeta } from "/app/pages/gate.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as initaPXqNYVSKtMeta } from "/app/pages/init.vue?macro=true";
import { default as introducelYo6pGqPHLMeta } from "/app/pages/introduce.vue?macro=true";
import { default as join5y5cAZNJvoMeta } from "/app/pages/join.vue?macro=true";
import { default as _91type_93O57CtlSTl5Meta } from "/app/pages/legal/[type].vue?macro=true";
import { default as legaljKqPCMARDuMeta } from "/app/pages/legal.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as maintenanceM1L3mPagESMeta } from "/app/pages/maintenance.vue?macro=true";
import { default as _91id_93FIXkK569FdMeta } from "/app/pages/news/[id].vue?macro=true";
import { default as indexoxV4GRc61hMeta } from "/app/pages/news/index.vue?macro=true";
import { default as newsWdQv0BaSvYMeta } from "/app/pages/news.vue?macro=true";
import { default as nicknameIPIP2Ct6DWMeta } from "/app/pages/nickname.vue?macro=true";
import { default as _91id_93R7ovraIi4kMeta } from "/app/pages/notice/[id].vue?macro=true";
import { default as indexi5mWV2lpAmMeta } from "/app/pages/notice/index.vue?macro=true";
import { default as noticexppzncyk55Meta } from "/app/pages/notice.vue?macro=true";
import { default as detail8U7GdjnqvgMeta } from "/app/pages/settings/contact/detail.vue?macro=true";
import { default as indextjoPbbAFBcMeta } from "/app/pages/settings/contact/index.vue?macro=true";
import { default as writeOJ20NEUGSEMeta } from "/app/pages/settings/contact/write.vue?macro=true";
import { default as contactO7JjgO9rJaMeta } from "/app/pages/settings/contact.vue?macro=true";
import { default as delete_45accountYabUcVXoN8Meta } from "/app/pages/settings/delete-account.vue?macro=true";
import { default as emailbtxlYXJaMjMeta } from "/app/pages/settings/email.vue?macro=true";
import { default as index11eXGkSJBcMeta } from "/app/pages/settings/index.vue?macro=true";
import { default as nicknameEYyoKvl5qLMeta } from "/app/pages/settings/nickname.vue?macro=true";
import { default as passwordZQhuzffxi0Meta } from "/app/pages/settings/password.vue?macro=true";
import { default as settingsLwEYOlkQxrMeta } from "/app/pages/settings.vue?macro=true";
import { default as termJlyDIwxnBpMeta } from "/app/pages/term.vue?macro=true";
import { default as textbookYeHqhKUocVMeta } from "/app/pages/textbook.vue?macro=true";
import { default as web_45playvvzXbM6VlXMeta } from "/app/pages/web-play.vue?macro=true";
export default [
  {
    name: download0ovpAXlwqnMeta?.name ?? "download",
    path: download0ovpAXlwqnMeta?.path ?? "/download",
    meta: download0ovpAXlwqnMeta || {},
    alias: download0ovpAXlwqnMeta?.alias || [],
    redirect: download0ovpAXlwqnMeta?.redirect,
    component: () => import("/app/pages/download.vue").then(m => m.default || m)
  },
  {
    name: education_45resourcesPlIHN1aw1jMeta?.name ?? "education-resources",
    path: education_45resourcesPlIHN1aw1jMeta?.path ?? "/education-resources",
    meta: education_45resourcesPlIHN1aw1jMeta || {},
    alias: education_45resourcesPlIHN1aw1jMeta?.alias || [],
    redirect: education_45resourcesPlIHN1aw1jMeta?.redirect,
    component: () => import("/app/pages/education-resources.vue").then(m => m.default || m)
  },
  {
    name: faqIMkmXQpKKaMeta?.name ?? "faq",
    path: faqIMkmXQpKKaMeta?.path ?? "/faq",
    meta: faqIMkmXQpKKaMeta || {},
    alias: faqIMkmXQpKKaMeta?.alias || [],
    redirect: faqIMkmXQpKKaMeta?.redirect,
    component: () => import("/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: gateLrWVWI1DmOMeta?.name ?? "gate",
    path: gateLrWVWI1DmOMeta?.path ?? "/gate",
    meta: gateLrWVWI1DmOMeta || {},
    alias: gateLrWVWI1DmOMeta?.alias || [],
    redirect: gateLrWVWI1DmOMeta?.redirect,
    component: () => import("/app/pages/gate.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: initaPXqNYVSKtMeta?.name ?? "init",
    path: initaPXqNYVSKtMeta?.path ?? "/init",
    meta: initaPXqNYVSKtMeta || {},
    alias: initaPXqNYVSKtMeta?.alias || [],
    redirect: initaPXqNYVSKtMeta?.redirect,
    component: () => import("/app/pages/init.vue").then(m => m.default || m)
  },
  {
    name: introducelYo6pGqPHLMeta?.name ?? "introduce",
    path: introducelYo6pGqPHLMeta?.path ?? "/introduce",
    meta: introducelYo6pGqPHLMeta || {},
    alias: introducelYo6pGqPHLMeta?.alias || [],
    redirect: introducelYo6pGqPHLMeta?.redirect,
    component: () => import("/app/pages/introduce.vue").then(m => m.default || m)
  },
  {
    name: join5y5cAZNJvoMeta?.name ?? "join",
    path: join5y5cAZNJvoMeta?.path ?? "/join",
    meta: join5y5cAZNJvoMeta || {},
    alias: join5y5cAZNJvoMeta?.alias || [],
    redirect: join5y5cAZNJvoMeta?.redirect,
    component: () => import("/app/pages/join.vue").then(m => m.default || m)
  },
  {
    name: legaljKqPCMARDuMeta?.name ?? "legal",
    path: legaljKqPCMARDuMeta?.path ?? "/legal",
    meta: legaljKqPCMARDuMeta || {},
    alias: legaljKqPCMARDuMeta?.alias || [],
    redirect: legaljKqPCMARDuMeta?.redirect,
    component: () => import("/app/pages/legal.vue").then(m => m.default || m),
    children: [
  {
    name: _91type_93O57CtlSTl5Meta?.name ?? "legal-type",
    path: _91type_93O57CtlSTl5Meta?.path ?? ":type()",
    meta: _91type_93O57CtlSTl5Meta || {},
    alias: _91type_93O57CtlSTl5Meta?.alias || [],
    redirect: _91type_93O57CtlSTl5Meta?.redirect,
    component: () => import("/app/pages/legal/[type].vue").then(m => m.default || m)
  }
]
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login",
    path: loginhHM0vSTW5jMeta?.path ?? "/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: maintenanceM1L3mPagESMeta?.name ?? "maintenance",
    path: maintenanceM1L3mPagESMeta?.path ?? "/maintenance",
    meta: maintenanceM1L3mPagESMeta || {},
    alias: maintenanceM1L3mPagESMeta?.alias || [],
    redirect: maintenanceM1L3mPagESMeta?.redirect,
    component: () => import("/app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: newsWdQv0BaSvYMeta?.name ?? undefined,
    path: newsWdQv0BaSvYMeta?.path ?? "/news",
    meta: newsWdQv0BaSvYMeta || {},
    alias: newsWdQv0BaSvYMeta?.alias || [],
    redirect: newsWdQv0BaSvYMeta?.redirect,
    component: () => import("/app/pages/news.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93FIXkK569FdMeta?.name ?? "news-id",
    path: _91id_93FIXkK569FdMeta?.path ?? ":id()",
    meta: _91id_93FIXkK569FdMeta || {},
    alias: _91id_93FIXkK569FdMeta?.alias || [],
    redirect: _91id_93FIXkK569FdMeta?.redirect,
    component: () => import("/app/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: indexoxV4GRc61hMeta?.name ?? "news",
    path: indexoxV4GRc61hMeta?.path ?? "",
    meta: indexoxV4GRc61hMeta || {},
    alias: indexoxV4GRc61hMeta?.alias || [],
    redirect: indexoxV4GRc61hMeta?.redirect,
    component: () => import("/app/pages/news/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: nicknameIPIP2Ct6DWMeta?.name ?? "nickname",
    path: nicknameIPIP2Ct6DWMeta?.path ?? "/nickname",
    meta: nicknameIPIP2Ct6DWMeta || {},
    alias: nicknameIPIP2Ct6DWMeta?.alias || [],
    redirect: nicknameIPIP2Ct6DWMeta?.redirect,
    component: () => import("/app/pages/nickname.vue").then(m => m.default || m)
  },
  {
    name: noticexppzncyk55Meta?.name ?? undefined,
    path: noticexppzncyk55Meta?.path ?? "/notice",
    meta: noticexppzncyk55Meta || {},
    alias: noticexppzncyk55Meta?.alias || [],
    redirect: noticexppzncyk55Meta?.redirect,
    component: () => import("/app/pages/notice.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93R7ovraIi4kMeta?.name ?? "notice-id",
    path: _91id_93R7ovraIi4kMeta?.path ?? ":id()",
    meta: _91id_93R7ovraIi4kMeta || {},
    alias: _91id_93R7ovraIi4kMeta?.alias || [],
    redirect: _91id_93R7ovraIi4kMeta?.redirect,
    component: () => import("/app/pages/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: indexi5mWV2lpAmMeta?.name ?? "notice",
    path: indexi5mWV2lpAmMeta?.path ?? "",
    meta: indexi5mWV2lpAmMeta || {},
    alias: indexi5mWV2lpAmMeta?.alias || [],
    redirect: indexi5mWV2lpAmMeta?.redirect,
    component: () => import("/app/pages/notice/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: settingsLwEYOlkQxrMeta?.name ?? undefined,
    path: settingsLwEYOlkQxrMeta?.path ?? "/settings",
    meta: settingsLwEYOlkQxrMeta || {},
    alias: settingsLwEYOlkQxrMeta?.alias || [],
    redirect: settingsLwEYOlkQxrMeta?.redirect,
    component: () => import("/app/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: contactO7JjgO9rJaMeta?.name ?? undefined,
    path: contactO7JjgO9rJaMeta?.path ?? "contact",
    meta: contactO7JjgO9rJaMeta || {},
    alias: contactO7JjgO9rJaMeta?.alias || [],
    redirect: contactO7JjgO9rJaMeta?.redirect,
    component: () => import("/app/pages/settings/contact.vue").then(m => m.default || m),
    children: [
  {
    name: detail8U7GdjnqvgMeta?.name ?? "settings-contact-detail",
    path: detail8U7GdjnqvgMeta?.path ?? "detail",
    meta: detail8U7GdjnqvgMeta || {},
    alias: detail8U7GdjnqvgMeta?.alias || [],
    redirect: detail8U7GdjnqvgMeta?.redirect,
    component: () => import("/app/pages/settings/contact/detail.vue").then(m => m.default || m)
  },
  {
    name: indextjoPbbAFBcMeta?.name ?? "settings-contact",
    path: indextjoPbbAFBcMeta?.path ?? "",
    meta: indextjoPbbAFBcMeta || {},
    alias: indextjoPbbAFBcMeta?.alias || [],
    redirect: indextjoPbbAFBcMeta?.redirect,
    component: () => import("/app/pages/settings/contact/index.vue").then(m => m.default || m)
  },
  {
    name: writeOJ20NEUGSEMeta?.name ?? "settings-contact-write",
    path: writeOJ20NEUGSEMeta?.path ?? "write",
    meta: writeOJ20NEUGSEMeta || {},
    alias: writeOJ20NEUGSEMeta?.alias || [],
    redirect: writeOJ20NEUGSEMeta?.redirect,
    component: () => import("/app/pages/settings/contact/write.vue").then(m => m.default || m)
  }
]
  },
  {
    name: delete_45accountYabUcVXoN8Meta?.name ?? "settings-delete-account",
    path: delete_45accountYabUcVXoN8Meta?.path ?? "delete-account",
    meta: delete_45accountYabUcVXoN8Meta || {},
    alias: delete_45accountYabUcVXoN8Meta?.alias || [],
    redirect: delete_45accountYabUcVXoN8Meta?.redirect,
    component: () => import("/app/pages/settings/delete-account.vue").then(m => m.default || m)
  },
  {
    name: emailbtxlYXJaMjMeta?.name ?? "settings-email",
    path: emailbtxlYXJaMjMeta?.path ?? "email",
    meta: emailbtxlYXJaMjMeta || {},
    alias: emailbtxlYXJaMjMeta?.alias || [],
    redirect: emailbtxlYXJaMjMeta?.redirect,
    component: () => import("/app/pages/settings/email.vue").then(m => m.default || m)
  },
  {
    name: index11eXGkSJBcMeta?.name ?? "settings",
    path: index11eXGkSJBcMeta?.path ?? "",
    meta: index11eXGkSJBcMeta || {},
    alias: index11eXGkSJBcMeta?.alias || [],
    redirect: index11eXGkSJBcMeta?.redirect,
    component: () => import("/app/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: nicknameEYyoKvl5qLMeta?.name ?? "settings-nickname",
    path: nicknameEYyoKvl5qLMeta?.path ?? "nickname",
    meta: nicknameEYyoKvl5qLMeta || {},
    alias: nicknameEYyoKvl5qLMeta?.alias || [],
    redirect: nicknameEYyoKvl5qLMeta?.redirect,
    component: () => import("/app/pages/settings/nickname.vue").then(m => m.default || m)
  },
  {
    name: passwordZQhuzffxi0Meta?.name ?? "settings-password",
    path: passwordZQhuzffxi0Meta?.path ?? "password",
    meta: passwordZQhuzffxi0Meta || {},
    alias: passwordZQhuzffxi0Meta?.alias || [],
    redirect: passwordZQhuzffxi0Meta?.redirect,
    component: () => import("/app/pages/settings/password.vue").then(m => m.default || m)
  }
]
  },
  {
    name: termJlyDIwxnBpMeta?.name ?? "term",
    path: termJlyDIwxnBpMeta?.path ?? "/term",
    meta: termJlyDIwxnBpMeta || {},
    alias: termJlyDIwxnBpMeta?.alias || [],
    redirect: termJlyDIwxnBpMeta?.redirect,
    component: () => import("/app/pages/term.vue").then(m => m.default || m)
  },
  {
    name: textbookYeHqhKUocVMeta?.name ?? "textbook",
    path: textbookYeHqhKUocVMeta?.path ?? "/textbook",
    meta: textbookYeHqhKUocVMeta || {},
    alias: textbookYeHqhKUocVMeta?.alias || [],
    redirect: textbookYeHqhKUocVMeta?.redirect,
    component: () => import("/app/pages/textbook.vue").then(m => m.default || m)
  },
  {
    name: web_45playvvzXbM6VlXMeta?.name ?? "web-play",
    path: web_45playvvzXbM6VlXMeta?.path ?? "/web-play",
    meta: web_45playvvzXbM6VlXMeta || {},
    alias: web_45playvvzXbM6VlXMeta?.alias || [],
    redirect: web_45playvvzXbM6VlXMeta?.redirect,
    component: () => import("/app/pages/web-play.vue").then(m => m.default || m)
  }
]