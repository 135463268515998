// locals
import type DeleteAccount from '~/pages/settings/delete-account.vue'
import FetchFactory from '../factory'

type AuthEnd = {
  authPGCode: string
  authTransactionToken: string
  authAccessCode: string
  memberType: string
  yearBirth: number | null
  authPGResult: {
    apiToken?: string
    resCd?: string
    encCertData2?: string
    certNo?: string
    dnHash?: string
    tid?: string
  }
}
export type { AuthEnd }
// https://dev-mverse-api.hellomaple.org/account/swagger-ui/swagger-ui/index.html?urls.primaryName=all#/withdraw/register
class AccountModule extends FetchFactory {
  private ACCOUNT = '/account/v1'

  /**
   * 아이디 중복 확인
   */
  checkDuplicateUserID(userId: string) {
    return this.call<any>('POST', `${this.ACCOUNT}/validate/userid`, {
      userid: userId
    })
  }
  /**
   * 이메일 중복 확인
   * retru
   */
  checkDuplicateEmail(email: string) {
    return this.call<any>('POST', `${this.ACCOUNT}/validate/email`, {
      email
    })
  }

  /**
   * 이메일 인증 코드 전송
   */
  sendCodeForEmail(email: string) {
    return this.call<any>(
      'POST',
      `${this.ACCOUNT}/verification/send/join/email`,
      { email }
    )
  }
  /**
   * 이메일 인증 코드 검증
   */
  verifyCodeForEmail(data: { email: string; keyCode: string }) {
    return this.call<any>(
      'PATCH',
      `${this.ACCOUNT}/verification/verify/join/email`,
      data
    )
  }
  authTransitionStart(targetUrl: string, authAccessCode: string) {
    return this.call<any>('POST', `${this.ACCOUNT}/auth/transaction/start`, {
      targetUrl,
      authAccessCode
    })
  }
  authTransitionEnd(data: AuthEnd) {
    return this.call<any>('POST', `${this.ACCOUNT}/auth/transaction/end`, {
      ...data
    })
  }
  /**
   * 유저 아이디를 찾기 위한 이메일을 전송한다.
   * @param email
   * @returns
   */
  findUserId(email: string) {
    return this.call<any>('POST', `${this.ACCOUNT}/verification/find/userid`, {
      email
    })
  }
  /**
   * 유저 비밀번호를 찾기 위한 이메일을 전송한다.
   * @param email
   * @returns
   */
  findPassword(email: string) {
    return this.call<any>(
      'POST',
      `${this.ACCOUNT}/verification/send/resetpassword`,
      {
        email
      }
    )
  }
  /**
   * 유저 비밀번호를 찾기 위한 이메일로 받은 인증 코드를 검증한다.
   * @param data
   * @returns
   */
  verifyCodeForPassword(data: { email: string; keyCode: string }) {
    return this.call<any>(
      'POST',
      `${this.ACCOUNT}/verification/verify/resetpassword`,
      data
    )
  }
  /**
   * 이메일로 인증을 거쳐 받은 토큰으로 비밀번호를 재설정한다.
   * @param param0
   * @returns
   */
  resetPasswordByToken({
    password,
    token
  }: {
    password: string
    token: string
  }) {
    return this.call<any>(
      'PATCH',
      `${this.ACCOUNT}/verification/verify/resetpassword/token`,
      {
        newPassword: password,
        passwordResetToken: token
      }
    )
  }
  /**
   * 이메일 등록 및 변경을 위한 메일 발송
   * @param email
   * @returns
   */
  sendCodeForUpdateEmail(email: string) {
    return this.call<any>(
      'POST',
      `${this.ACCOUNT}/verification/send/my/email`,
      { email },
      { authorization: true }
    )
  }
  /**
   * 이메일 등록 및 변경을 위한 인증 코드 검증
   * @param data
   * @returns
   */
  verifyCodeForUpdateEmail(data: { email: string; keyCode: string }) {
    return this.call<any>(
      'PATCH',
      `${this.ACCOUNT}/verification/verify/my/email`,
      data,
      { authorization: true }
    )
  }
  /**
   * 로그인 해제를 위한 이메일로 인증 코드를 전송한다.
   * @param param0
   * @returns
   */
  sendCodeForUnlockAccount({
    email,
    userId
  }: {
    email: string
    userId: string
  }) {
    return this.call<any>(
      'POST',
      `${this.ACCOUNT}/verification/send/lockaccount`,
      {
        email,
        userid: userId
      }
    )
  }
  /**
   * 로그인 해제를 위한 이메일로 인증 코드를 검증한다
   * @param param0
   * @returns
   */
  verifyCodeForUnlockAccount({
    keyCode,
    userId
  }: {
    keyCode: string
    userId: string
  }) {
    return this.call<any>(
      'PATCH',
      `${this.ACCOUNT}/verification/verify/lockaccount`,
      {
        keyCode,
        userid: userId
      }
    )
  }
  /**
   * 탈퇴 신청을 한다.
   * @param password
   * @returns
   */
  deleteAccount(password: string) {
    return this.call<any>(
      'POST',
      `${this.ACCOUNT}/withdraw/register`,
      {
        password
      },
      { authorization: true }
    )
  }
  /**
   * 탈퇴 신청 취소를 한다.
   * @param param0
   * @returns
   */
  cancelDeleteAccount({
    userId,
    password
  }: {
    userId: string
    password: string
  }) {
    return this.call<any>('DELETE', `${this.ACCOUNT}/withdraw/cancel`, {
      userid: userId,
      password
    })
  }
}

export default AccountModule
