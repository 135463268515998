<template>
  <div
    v-show="isVisible"
    ref="tooltipRef"
    class="tooltip"
    :class="tooltipPosition"
    :style="tooltipStyle"
    v-html="tooltipContent"
  ></div>
</template>

<script lang="ts" setup>
const MARGIN = 15
const tooltipRef = ref<HTMLElement | null>(null)
const tooltipStyle = ref({})
const router = useRouter()

const {
  isVisible,
  tooltipTarget,
  tooltipContent,
  tooltipPosition,
  hideTooltip
} = useTooltip()
const margin = computed(() => {
  if (tooltipPosition.value === 'right') {
    return MARGIN + 8
  }
  return MARGIN
})

watch(router.currentRoute, hideTooltip)
watch(isVisible, (n) => {
  nextTick(() => {
    if (n) updateStyle()
  })
})
const updateStyle = () => {
  if (process.server) return
  const target = tooltipTarget.value
  if (tooltipRef.value && target) {
    const { innerWidth, scrollY } = window
    const { top, left, right, bottom, width, height } = (
      target! as HTMLElement
    ).getBoundingClientRect()
    const newTop = scrollY + top

    const { width: tooltipWidth, height: tooltipHeight } = (
      tooltipRef.value! as HTMLElement
    ).getBoundingClientRect()
    switch (tooltipPosition.value) {
      case 'top':
        tooltipStyle.value = {
          top: `${newTop - tooltipHeight - margin.value}px`,
          left: `${left}px`
        }
        break
      case 'left':
        tooltipStyle.value = {
          top: `${newTop + (height - tooltipHeight) / 2}px`,
          left: `${left - (tooltipWidth + margin.value)}px`
        }
        break
      case 'right':
        tooltipStyle.value = {
          top: `${newTop + (height - tooltipHeight) / 2}px`,
          left: `${right + margin.value}px`
        }
        break
      case 'bottom':
        {
          let _left = left
          if (innerWidth < left + tooltipWidth) {
            const _right = innerWidth - right
            _left = left - (left + tooltipWidth - innerWidth) - _right
          }
          tooltipStyle.value = {
            top: `${newTop + height + margin.value}px`,
            left: `${_left}px`
          }
        }
        break
    }
  }
}
onMounted(() => {
  window.addEventListener('resize', hideTooltip)
})
onUnmounted(() => {
  window.removeEventListener('resize', hideTooltip)
})
</script>

<style scoped lang="scss">
.tooltip {
  position: absolute;
  padding: 8px 16px 10px 16px;
  color: $white;
  border-radius: 10px;
  background: rgba(24, 25, 28, 0.7);
  border-radius: 10px;
  box-shadow:
    0px 2px 3px 0px rgba(0, 0, 0, 0.15),
    2px 2px 5px 0px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(7.5px);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  &.right {
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: -8px; /* 삼각형이 tooltip의 왼쪽에 위치하도록 */
      transform: translateY(
        -50%
      ); /* 삼각형의 높이 절반만큼 상단으로 올려서 가운데 정렬 */
      width: 0;
      height: 0;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-right: 8px solid rgba(24, 25, 28, 0.7);
    }
  }
}
</style>
