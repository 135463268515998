export const useTooltip = () => {
  const tooltipContent = useState(() => '')
  const tooltipPosition = useState(() => 'top')
  const tooltipVisible = computed(() => !!tooltipContent.value)
  const tooltipTarget = useState<EventTarget | null>(() => null)
  const showTooltip = ({
    event,
    content,
    position
  }: {
    event: MouseEvent
    content: string
    position?: string
  }) => {
    tooltipTarget.value = event?.target
    tooltipContent.value = content
    if (position) {
      tooltipPosition.value = position
    }
  }

  const hideTooltip = () => {
    tooltipContent.value = ''
  }

  return {
    isVisible: tooltipVisible,
    tooltipContent,
    tooltipPosition,
    tooltipTarget,
    showTooltip,
    hideTooltip
  }
}
