<template>
  <atoms-base-button class="btn_outlined" v-bind="$attrs">
    <slot />
  </atoms-base-button>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.btn_outlined {
  box-sizing: border-box;
  padding: 6px 8px;
  border: 2px solid $gray2;
  background-color: $white;
  border-radius: 8px;
  @include transition();
  &:not(:disabled):hover,
  &:active {
    background-color: #f6f8fc;
  }
  &:disabled {
    //background-color: $btn-disable;
    &:deep(.txt_btn) {
      color: $gray0;
    }
  }
  &:deep(.txt_btn) {
    font-size: 14px;
    font-weight: 700;
    line-height: 150%; /* 21px */
    color: $blue1;
  }
}
</style>
