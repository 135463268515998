// locals
import FetchFactory from '../factory'

type LoginParams = {
  userId: string
  password: string
}
class AuthModule extends FetchFactory {
  private AUTH = '/auth'
  /**
   * 로그인
   * @param params - userid, password
   * @returns
   */
  login({ userId, password }: LoginParams) {
    return this.call<any>('POST', `${this.AUTH}/v1/token/login`, {
      userid: userId,
      password
    })
  }
  logout() {
    return this.call<any>('POST', `${this.AUTH}/v1/token/logout`, undefined, {
      authorization: true
    })
  }
  renewToken(refreshToken: string) {
    return this.call<any>('POST', `${this.AUTH}/v1/token/refresh`, {
      refreshToken
    })
  }
  changePassword({
    password,
    newPassword
  }: {
    password: string
    newPassword: string
  }) {
    return this.call<any>(
      'PATCH',
      `${this.AUTH}/v1/change/password`,
      {
        oldPassword: password,
        newPassword
      },
      {
        authorization: true
      }
    )
  }
}

export default AuthModule
