import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/app/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_3rw1Iqhpn2 from "/app/node_modules/vue3-perfect-scrollbar/nuxt/dist/runtime/plugin.mjs";
import plugin_8SbxDRbG6Y from "/app/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_t2GMTTFnMT from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import index_7iZUWb444j from "/app/node_modules/nuxtjs-phaser/index.js";
import a2s_GAr3n6oAby from "/app/plugins/a2s.ts";
import api_GFfDXud5Cr from "/app/plugins/api.ts";
import device_PxhEqcDcOg from "/app/plugins/device.ts";
import dompurify_html_client_VOUn5jEdfF from "/app/plugins/dompurify-html.client.ts";
import init_client_lHDJZN4lq2 from "/app/plugins/init.client.ts";
import my_directive_client_vuFMnIArGF from "/app/plugins/my-directive.client.ts";
import toastify_ScRgZgP9op from "/app/plugins/toastify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  plugin_3rw1Iqhpn2,
  plugin_8SbxDRbG6Y,
  plugin_t2GMTTFnMT,
  chunk_reload_client_UciE0i6zes,
  index_7iZUWb444j,
  a2s_GAr3n6oAby,
  api_GFfDXud5Cr,
  device_PxhEqcDcOg,
  dompurify_html_client_VOUn5jEdfF,
  init_client_lHDJZN4lq2,
  my_directive_client_vuFMnIArGF,
  toastify_ScRgZgP9op
]