interface ModalInfo {
  className?: string
  title?: string
  content: string
  confirmText?: string
  closeText?: string
  callback?: Function
  fallback?: Function
}
interface Modal extends ModalInfo {
  type: string // alert, confirm
}
const MODAL: Modal = {
  className: '',
  type: '',
  title: '',
  content: '',
  confirmText: '',
  closeText: '',
  callback: () => {},
  fallback: () => {}
}

export const useModalStore = defineStore('modal', () => {
  const callStack = ref<Array<Modal>>([])
  const modal = computed<Modal | undefined>(() => [...callStack.value].pop())

  const isOpenAlert = computed(() => {
    return callStack.value.some((m) => m.type === 'alert')
  })
  const isOpenConfirm = computed(() => {
    return callStack.value.some((m) => m.type === 'confirm')
  })

  const topType = computed(() => {
    return modal.value?.type
  })
  const isOpen = computed(() => {
    return !!modal.value
  })
  const openModal = (payload: Modal) => {
    callStack.value.push(payload)
  }
  const closeModal = (type: string) => {
    callStack.value = callStack.value.filter((modal) => modal.type !== type)
  }
  const openAlertModal = (payload: ModalInfo) => {
    openModal({ type: 'alert', ...payload })
  }

  const openConfirmModal = (payload: ModalInfo) => {
    openModal({ type: 'confirm', ...payload })
  }

  return {
    modal,
    topType,
    isOpen,
    isOpenAlert,
    isOpenConfirm,
    closeModal,
    openAlertModal,
    openConfirmModal
  }
})
