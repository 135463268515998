import type { $Fetch, FetchOptions } from 'ofetch'
import type { AsyncDataOptions } from '#app'

/*
 The FetchFactory acts as a wrapper around an HTTP client.
 It encapsulates the functionality for making API requests asynchronously
 through the call function, utilizing the provided HTTP client.
*/
class FetchFactory {
  private $fetch: $Fetch
  private controllers: { [index: string]: AbortController | null }
  constructor(fetch: $Fetch) {
    this.$fetch = fetch
    this.controllers = {}
  }

  /**
   * The HTTP client is utilized to control the process of making API requests.
   * @param method the HTTP method
   * @param url the endpoint url
   * @param data the body data
   * @param fetchOptions fetch options
   * @returns
   */
  call<T>(
    method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE',
    url: string,
    data?: object | File | FormData | ArrayBuffer,
    fetchOptions?: FetchOptions<'json'>
  ): Promise<T> {
    if (this.controllers[url]) {
      this.controllers[url]?.abort()
      this.controllers[url] = null
    }
    const controller = new AbortController()
    this.controllers[url] = controller

    return this.$fetch<T>(url, {
      method,
      body: data,
      signal: controller.signal,
      ...fetchOptions
    })
  }
}

export default FetchFactory
