<template>
  <button
    class="btn_border"
    :class="{ font_maple_story: !minimum, [colorPack]: colorPack, minimum }"
    :type="type"
  >
    <span class="base_slot">
      <slot>
        {{ text }}
      </slot>
    </span>
    <atoms-c-icon
      v-if="appendIcon"
      :icon-name="appendIcon"
      class="icon_append"
    />
  </button>
</template>

<script lang="ts" setup>
interface Props {
  type?: 'button' | 'submit' | 'reset'
  text?: string
  colorPack?:
    | 'blue'
    | 'yellow'
    | 'gray'
    | 'salamon'
    | 'pink'
    | 'orange'
    | 'purple'
    | 'green'
  appendIcon?: string
  minimum?: boolean
}

const { type, text, colorPack, appendIcon, minimum } = withDefaults(
  defineProps<Props>(),
  {
    type: 'button',
    text: '',
    colorPack: 'blue',
    appendIcon: '',
    minimum: false
  }
)
</script>

<style lang="scss" scoped>
.btn_border {
  display: flex;
  border-radius: 16px;
  padding: 20px 24px;
  justify-content: space-between;
  align-items: center;
  color: $white;
  gap: 16px;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.8px;
  @include transition();

  &.blue {
    background-color: $blue1;
    box-shadow: 0 8px 0 0 #3743c4;
    &:not(:disabled):hover {
      background-color: #3532da;
      box-shadow: 0 8px 0 0 #211e9b;
    }
  }
  &.yellow {
    color: $black0;
    background-color: #ffe130;
    box-shadow: 0 8px 0 0 #fcb500;
    &:not(:disabled):hover {
      background-color: #ffc700;
      box-shadow: 0 8px 0 0 #ffa800;
    }
  }
  &.gray {
    color: #393c41;
    background-color: #f1f2f4;
    box-shadow: 0 8px 0 0 rgba(241, 242, 244, 0.6);
    &:not(:disabled):hover {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.02) 0%,
          rgba(0, 0, 0, 0.02) 100%
        ),
        #f1f2f4;
      box-shadow: 0 8px 0 0 rgba(241, 242, 244, 0.6);
    }
  }
  &.salamon {
    color: #94482f;
    background-color: $salmon-pink1;
    box-shadow: 0px 8px 0px 0px #f29577;
    &:not(:disabled):hover {
      background-color: #ffb197;
    }
  }
  &.pink {
    background-color: $red-pink1;
    box-shadow: 0px 8px 0px 0px #e94c69;
    &:not(:disabled):hover {
      background-color: #ff95a8;
    }
  }
  &.orange {
    background-color: $orange0;
    box-shadow: 0px 8px 0px 0px #f06419;
    &:not(:disabled):hover {
      background-color: #ff9c18;
    }
  }
  &.purple {
    background-color: #8a4bff;
    box-shadow: 0px 8px 0px 0px #6c34d4;
    &:not(:disabled):hover {
      background-color: #a16fff;
    }
  }
  &.green {
    background-color: #15c3af;
    box-shadow: 0px 8px 0px 0px #02968d;
    &:not(:disabled):hover {
      background-color: #02968d;
      box-shadow: 0px 8px 0px 0px #027871;
    }
  }
  &:disabled {
    background-color: $btn-disable;
    box-shadow: 0 8px 0 0 #cacde7;
  }
  .icon_append {
    width: 28px !important;
    height: 28px !important;
  }
  .base_slot {
    display: flex;
    align-items: center;
  }
  &.minimum {
    padding: 14px;
    border-radius: 12px;
    background: #e7effb;
    gap: 8px;
    font-size: 18px;
    font-weight: 700;
    box-shadow: none;
    color: $blue1;
    &:not(:disabled):hover {
      background-color: $bg-l-blue0;
      box-shadow: none;
    }
  }
}
@media (max-width: 999px) {
  .btn_border {
    padding: 16px 20px;
    font-size: 16px;
    line-height: 1;
    letter-spacing: -0.64px;
    .icon_append {
      width: 24px !important;
      height: 24px !important;
    }
  }
}
</style>
