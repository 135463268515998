// locals
import FetchFactory from '../factory'

type LoginParams = {
  userId: string
  password: string
}
class ProfileModule extends FetchFactory {
  private AUTH = '/profile'
  /**
   * 내 프로필 정보를 불러온다
   * @returns
   */
  getMyProfile() {
    return this.call<any>('GET', `${this.AUTH}/v1/web`, undefined, {
      authorization: true
    })
  }
  /**
   * 프로필 생성, 비밀번호 교체(학급계정), 약관동의
   * @param params
   * @returns
   */
  createProfile(params: {
    profileName?: string
    password?: string
    temrs?: []
  }) {
    return this.call<any>('POST', `${this.AUTH}/v1`, params, {
      authorization: true
    })
  }
  /**
   * 프로필 이름 변경
   * @param profileName
   * @returns
   */
  updateProfileName(profileName: string) {
    return this.call<any>(
      'PUT',
      `${this.AUTH}/v1/profileName`,
      { profileName },
      {
        authorization: true
      }
    )
  }
}

export default ProfileModule
