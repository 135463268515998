import { defineStore } from 'pinia'
interface Classroom {
  classroomName: string
  studentName: string
  studentNo: string | number
}
interface Profile {
  ppsn: string | null
  profileName: string | null
  profileCode: string | null
  profileImageUrl: string | null
  badges?: Array<any>
  verifiedBadge?: string
  bans?: Array<any>
  useAutoUpdateProfile?: boolean
  classroomInfo?: Classroom
}
interface Account {
  accountId: string
  accountType: string
  userKey?: string
  email?: string
  mobilePhone?: null | string
}

const ACCOUNT_INFO = {
  accountType: '',
  accountId: '',
  userKey: '',
  email: '',
  mobilePhone: null
}
const CLASSROM_INFO = {
  classroomName: '',
  studentName: '',
  studentNo: ''
}
const PROFILE_INFO = {
  ppsn: '',
  profileName: '',
  profileCode: '',
  profileImageUrl: '',
  badges: [],
  verifiedBadge: '',
  bans: [],
  useAutoUpdateProfile: false,
  classroomInfo: CLASSROM_INFO
}

import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, STUDENT } from '@/utils/constants'

export const useAuthStore = defineStore('auth', () => {
  const nuxtApp = useNuxtApp()
  const route = useRoute()
  const router = useRouter()
  const { $api } = nuxtApp
  const { accessToken, refreshToken } = useAuthToken()
  //const accessToken = useCookie('_a_t', { path: '/', })

  /* state */
  const terms = ref<Array<any>>([])
  const account = ref<Account>({ ...ACCOUNT_INFO })
  const profile = ref<Profile>({ ...PROFILE_INFO })

  /* getters */

  // 프로필 여부
  const hasProfile = computed(() => {
    // 학생은 약관 동의 여부로 프로필 존재 여부를 판단
    // 일반, 선생님은 프로필 코드로 판단
    return !!profile.value.profileCode /* (
      (account.value.accountType === STUDENT && terms.value.length === 0) ||
      (account.value.accountType !== STUDENT && !!profile.value.profileCode)
    ) */
  })
  const profileCode = computed(() => profile.value.profileCode)
  const profileName = computed(() => profile.value.profileName)
  const accountType = computed(() => account.value.accountType)
  const classroom = computed(() => profile.value.classroomInfo)
  const isRequriedInit = computed(() => {
    return !!accessToken.value && hasProfile.value === false
  })
  const setProfile = (newProfile: Profile) => {
    profile.value = { ...profile.value, ...newProfile }
  }

  /* actions */
  const $reset = () => {
    terms.value = []
    account.value = { ...ACCOUNT_INFO }
    profile.value = { ...PROFILE_INFO }
  }

  const setAccount = (newAccount: Account) => {
    account.value = { ...account.value, ...newAccount }
  }

  const setTerms = (newTerms: Array<any>) => {
    console.log('setTerms', newTerms)
    terms.value = newTerms
  }

  const login = async ({
    userId,
    password
  }: {
    userId: string
    password: string
  }): Promise<number> => {
    try {
      const resp = await $api.auth.login({
        userId,
        password
      })
      if (resp) {
        const { data, code } = resp
        if (code === 0) {
          const {
            accessToken: _at,
            accessTokenExpiresIn,
            refreshToken: _rt,
            refreshTokenExpiresIn,
            terms,
            profileInfo: { accountInfo, ...restProfile }
          } = data
          accessToken.value = _at
          refreshToken.value = _rt
          /*  updateToken({
            type: ACCESS_TOKEN_KEY,
            newToken: accessToken,
            maxAge: accessTokenExpiresIn
          })
          updateToken({
            type: REFRESH_TOKEN_KEY,
            newToken: refreshToken,
            maxAge: refreshTokenExpiresIn
          }) */
          setTerms(terms)
          setAccount(accountInfo)
          setProfile(restProfile)

          // 프로필 이름이 없는 경우
          // auccontType이 GENERAL|TEACHER
        }

        // 학생계정
        return code
      }

      throw new Error('login failed')
    } catch (e) {
      console.log(e)
      return -1
    }
  }
  const logout = async (redirectPath?: string) => {
    try {
      // 성공 여부는 의미 없음
      if (accessToken.value) {
        await $api.auth.logout()
      }
    } catch (e) {
      console.log(e)
    } finally {
      $reset()
      accessToken.value = null
      refreshToken.value = null
      if (redirectPath) {
        router.push(redirectPath)
      } else {
        const routeName = route.name as string
        if (/^(settings|init|nickname)/.test(routeName)) {
          router.push('/')
        }
      }
    }

    return true
  }

  const getMyProfile = async () => {
    try {
      console.log('getMyProfile')
      const resp = await $api.profile.getMyProfile()

      if (resp) {
        const { code } = resp
        switch (code) {
          case 0:
            {
              // 프로필 정보 저장
              console.log('valid profile', resp)
              const { terms, profileInfo } = resp.data
              if (profileInfo) {
                const { accountInfo, ...restProfile } = profileInfo
                setAccount(accountInfo)
                setProfile(restProfile)
              }

              setTerms(terms ?? [])
            }

            break
          case PROFILE_ERROR_CODE.NO_PROFILE:
            setProfile({
              ppsn: '',
              profileName: '',
              profileCode: '',
              profileImageUrl: ''
            })
            break

          default:
            // error
            break
        }
        return code
      }
      return -1
    } catch (e: any) {
      console.log('getMyProfile err', e)
      if (process.client) {
        if (e.name === 'FetchError' && e.message?.includes('aborted')) {
          return
        }
      }

      return e?.code ?? -1
    }
  }

  return {
    hasProfile,
    terms,
    account,
    profile,
    profileCode,
    profileName,
    accountType,
    isRequriedInit,

    classroom,
    setAccount,
    setTerms,
    setProfile,
    login,
    logout,
    getMyProfile
    /*     checkHasProfileAfterLogin,
    checkAfterSignUp */
  }
})
