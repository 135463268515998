import FetchFactory from '../factory'

class ContactModule extends FetchFactory {
  private CONTACT = '/ncs'
  private MEMBER_TYPE = 'MT0102'
  private CHANNELING = 'CN0116'

  uploadFile({ fileName, stream }: { fileName: string; stream: ArrayBuffer }) {
    return this.call('POST', `${this.CONTACT}/UploadFile/${fileName}`, stream, {
      authorization: true
    })
  }
  downloadFile({ detailCSID, fileId }: { detailCSID: number; fileId: number }) {
    return this.call(
      'GET',
      `${this.CONTACT}/DownloadFile/${fileId}`,
      undefined,
      { params: { receptionID: detailCSID }, authorization: true }
    )
  }
  /**
   * CS 카테고리를 불러온다.
   * @param params
   * @returns
   */
  getCSCategory(params: any) {
    return this.call('GET', `${this.CONTACT}/GetCategoryList`, undefined, {
      params,
      authorization: true
    })
  }
  /**
   * 나의 문의 목록을 불러온다.
   * @param params
   * @returns
   */
  getMyCSList(params: any) {
    return this.call(
      'GET',
      `${this.CONTACT}/GetReceptionMemberList`,
      undefined,
      {
        params: {
          ...params,

          memberType: this.MEMBER_TYPE,
          channeling: this.CHANNELING
        },
        authorization: true
      }
    )
  }
  /**
   * 신규 문의를 생성한다
   * @param data
   * @returns
   */
  reqNewCS(data: NewCS) {
    return this.call(
      'POST',
      `${this.CONTACT}/CreateReceptionMember`,
      {
        ...data,
        memberType: this.MEMBER_TYPE,
        channeling: this.CHANNELING
      },
      {
        authorization: true,
        headers: {
          'Content-Type': 'text/plain'
        }
      }
    )
  }
  cancelCS(id: any) {
    return this.call('POST', `${this.CONTACT}/CancelReception`, undefined, {
      params: {
        receptionID: id
      },
      authorization: true
    })
  }
  getCSDetail(id: any) {
    return this.call('GET', `${this.CONTACT}/GetReceptionMember`, undefined, {
      params: {
        receptionID: id,
        memberType: this.MEMBER_TYPE,
        channeling: this.CHANNELING
      },
      authorization: true
    })
  }
}

export default ContactModule
