export const useInterval = (callback: Function) => {
  const timerId = ref<ReturnType<typeof setInterval> | number>(0)

  const start = (time = 1000) => {
    stop()
    timerId.value = setInterval(callback, time)
  }

  const stop = () => {
    if (timerId.value !== 0) {
      clearInterval(timerId.value)
      timerId.value = 0
    }
  }

  return { start, stop, timerId }
}
