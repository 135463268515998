import {
  PROFILE_ERROR_CODE,
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY
} from '@/utils/constants'
const TEN_MINUTES = 600000
export default defineNuxtPlugin({
  name: 'init',
  dependsOn: ['api'],
  async setup(nuxtApp) {
    const { $api } = nuxtApp
    // const refreshTimer = ref<ReturnType<typeof setInterval>>()
    const { accessToken, refreshToken, lastSetTime } = useAuthToken()
    const authStore = useAuthStore()
    const { logout, getMyProfile } = authStore
    const { isRequriedInit } = storeToRefs(authStore)
    const { start, stop } = useInterval(renewToken) //
    const route = useRoute()
    const router = useRouter()
    const currentRouteName = computed(() => router.currentRoute.value.name)

    watch(
      accessToken,
      (
        newVal: string | null | undefined,
        oldValue: string | null | undefined
      ) => {
        console.log('[init.client] watch', newVal, oldValue)

        if (newVal && newVal !== oldValue) {
          start(TEN_MINUTES) // 타임아웃이랑 쪽같은데..? interval 쓰는 이유가 없는 듯
        } else if (newVal === null) {
          stop()
        }
      }
    )

    // 토큰을 갱신한다.
    async function renewToken(once = false) {
      try {
        if (!refreshToken.value) {
          throw new Error('No token')
          return
        }
        const oldAccessToken = accessToken.value
        if (once && oldAccessToken && lastSetTime.value) {
          const diff =
            parseInt(lastSetTime.value) + TEN_MINUTES - new Date().getTime()

          start(diff > 0 ? diff : 1000)
          return
        }

        const { data, code } = await ($api as any).auth.renewToken(
          refreshToken.value
        )
        if (code === 0 && data) {
          const {
            accessToken: at,
            accessTokenExpiresIn,
            refreshToken: rt,
            refreshTokenExpiresIn
          } = data

          accessToken.value = at
          refreshToken.value = rt
          // 최초 진입 시 Access token이 없었으면 프로필을 가져온다.
          if (once && !oldAccessToken) {
            setTimeout(async () => {
              const rvCode = await getMyProfile()
              if (rvCode === 0) {
                if (isRequriedInit.value) {
                  router.push('/init')
                } else if (currentRouteName.value === 'login') {
                  router.push('/')
                }
              }
            }, 100)
          }
        } else {
          throw new Error('Failed to renew token')
        }
      } catch (e) {
        console.log(['init error', e])
        // 토큰 갱신 실패시 로그아웃 처리
        await logout()
      }
    }

    renewToken(true)
  }
})
