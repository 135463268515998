<template>
  <NuxtLayout name="default">
    <div
      class="page_error"
      :class="{ [`status_${error?.statusCode}`]: error?.statusCode }"
    >
      <div class="container_error">
        <template v-if="error?.statusCode === 210">
          <h1 class="font_maple_story">
            <em>헬로메이플</em>
            <br />
            ˚웹 버전˚은
          </h1>
          <atoms-c-image
            class="img_yeti_web"
            file-name="error-yeti-web-play"
            :density="3"
          />
          <h1 class="font_maple_story">PC에서 즐겨요!</h1>
        </template>
        <template v-else>
          <atoms-c-image
            class="img_block img_block_1"
            width="160"
            height="63"
            file-name="block-cloud"
            :density="3"
          />
          <atoms-c-image
            class="img_block img_block_2"
            width="221"
            height="113"
            file-name="block-cloud-2"
            :density="3"
          />
          <atoms-c-image class="img_yeti" file-name="error-yeti" :density="3" />
        </template>
        <div class="txt_error">
          <template v-if="error?.statusCode === 404">
            원하시는 페이지를 찾을 수 없습니다.
            <br />
            입력한 주소를 다시 한번 확인해 주세요!
          </template>
          <template v-else-if="error?.statusCode === 210">
            스마트폰 플레이는 아직 지원하지 않습니다.
            <br />
            PC를 통해 접속해 주세요.
          </template>
          <template v-else>
            일시적인 오류가 발생했습니다.
            <br />
            잠시 후 다시 시도해 주세요.
          </template>
        </div>
        <atoms-border-button
          text="메인으로"
          append-icon="chevron_right_rouned"
          class="btn_main"
          @click="router.push({ name: 'index' })"
        ></atoms-border-button>
      </div>
    </div>
  </NuxtLayout>
  <modules-urgent-banner />
</template>
<script setup lang="ts">
const error = useError()
const router = useRouter()
</script>
<style scoped lang="scss">
.page_error {
  overflow: hidden;
  background-color: $bg-l-blue1;
  &.status_210 {
    background-color: #c2f4ff;
    .img_yeti_web {
      position: relative;
      margin-top: 15px;
      margin-left: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
    h1 {
      font-size: 28px;
      font-weight: 700;
      line-height: 140%; /* 39.2px */
      color: $black0;
      > em {
        color: #4b52ff;
      }
    }
  }
}
.container_error {
  padding: 235px 0 214px;
  position: relative;
  max-width: 430px;
  margin: 0 auto;
  color: #000;
  text-align: center;
  .txt_error {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    padding: 10px 0 20px;
  }
  .btn_main {
    width: 200px;
    margin: 0 auto;
  }
  .img_block {
    position: absolute;
    &.img_block_1 {
      top: 699px;
      left: -490px;
    }
    &.img_block_2 {
      top: 385px;
      right: -505px;
    }
  }
}
@media (max-width: 999px) {
  .container_error {
    padding: 170px 0 166px;
    min-width: 360px;
    max-width: 360px;
    .txt_error {
      font-size: 18px;
      line-height: 1.5;
      padding: 16px 0 20px;
    }
    .btn_main {
      width: 180px;
    }
    .img_yeti {
      height: 223px;
    }
  }
}
</style>
