const EMAIL_MSG_OBJ = {
  EMAIL_VALID: '이메일 인증이 완료되었습니다.',
  EMAIL_RULE: '올바른 이메일 형식이 아닙니다.',
  EMAIL_ALREADY: '이미 사용 중인 이메일 입니다.',
  EMAIL_CODE_EXPIRED: '인증번호 유효기간이 만료되었습니다.',
  EMAIL_NOT_CODE: '입력하신 인증번호가 올바르지 않습니다.'
}
const NICKNAME_MSG_OBJ = {
  NICKNAME_VALID: '사용 가능한 닉네임입니다.',
  NICKNAME_RULE_LENGTH: '프로필 이름은 2~16자 이내여야 합니다.',
  NICKNAME_RULE_UNDERBAR: '프로필 이름은 _으로 시작하거나 끝날 수 없습니다.',
  NICKNAME_RULE_CHAR: '온전한 문자,숫자 및 _만 사용할 수 있습니다.',
  NICKNAME_RULE_SPACE: '프로필 이름에는 공백을 포함할 수 없습니다.',
  NICKNAME_RULE_HARMFUL: '부적절한 단어는 사용할 수 없습니다.',
  NICKNAME_RULE_SAME: '현재 프로필 이름과 동일합니다.',
  NICKNAME_RULE_LIMIT:
    '프로필 이름 변경 횟수를 초과하였습니다.\n* 14일마다 기간 내 최대 2회 변경 가능'
}

const PWD_MSG_OBJ = {
  PWD_VALID: '사용 가능한 비밀번호입니다.',
  PWD_RULE:
    '비밀번호는 5~20자 사이의 영문, 숫자, 특수문자(!@#$%^*+=-)로 설정 가능합니다.',
  PWD_NOT_SAME: '비밀번호가 일치하지 않습니다.',
  PWD_SAME: '비밀번호가 일치합니다.',
  PWD_NOW_PLACEHOLDER: '지금 사용 중인 비밀번호를 입력해주세요.',
  PWD_PLACEHOLDER: '5~20자 사이의 영문, 숫자, 특수문자로 입력해주세요.',
  PWD_NEW_PLACEHOLDER: '5~20자 사이의 새로운 비밀번호를 입력해주세요.',
  PWD_RE_PLACEHOLDER: '비밀번호를 다시 한번 입력해주세요.',
  PWD_NEW_RE_PLACEHOLDER: '새로운 비밀번호를 한번 더 입력해주세요.',
  PWD_EXISITING_ERR:
    '현재 사용 중인 비밀번호가 일치하지 않습니다.\n바르게 입력 후 다시 시도해 주세요.',
  PWD_EXISITING_SAME_ERR:
    '사용 중인 비밀번호와 동일한 번호로는 변경할 수 없습니다.\n새 비밀번호 입력 후 다시 시도해 주세요.'
}
const ERR_MSG_OBJ: { [key: number]: string } = {
  99999: '알 수 없는 오류가 발생하였습니다.',
  99001: '잘못된 요청 정보입니다.',
  99002: '지원하지 않는 기능 입니다.',
  90003: '잘못된 입력 파라미터입니다.',
  90004: '토큰의 Client IP가 일치하지 않습니다.',
  90005: '인증 접근처가 유효하지 않습니다.',
  90006: 'PG사 코드가 유효하지 않습니다.',
  90010: '이미 사용 중인 아이디입니다.',
  90011: EMAIL_MSG_OBJ.EMAIL_ALREADY,
  90012: '올바른 아이디 형식이 아닙니다.',
  90013: '올바른 비밀번호 형식이 아닙니다.',
  90014: EMAIL_MSG_OBJ.EMAIL_RULE,
  90015: '본인 인증 토큰이 제공되지 않았습니다.',
  90016: '보호자 인증 토큰이 제공되지 않았습니다.',
  90017: '보호자 인증 가능한 계정 수 5개를 초과했습니다.',
  90018: '본인 인증 가능한 계정 수 3개를 초과했습니다.',
  90019: '성인만 보호자 인증이 가능합니다.',
  90020: '보호자와 나이 차이가 18세 이상이어야 합니다.',
  90021: '신상 정보가 유효하지 않습니다.',
  90022: '만 14세 이상만 본인 인증이 가능합니다.',
  90023: '유저아이디에 유해 텍스트가 포함되어 있습니다.',
  90024: '이메일 인증 토큰이 유효하지 않습니다.',
  90030: '유효하지 않은 회원정보 입니다.',
  90031: PWD_MSG_OBJ.PWD_EXISITING_ERR, //'비밀번호가 유효하지 않습니다.',
  90032: PWD_MSG_OBJ.PWD_EXISITING_SAME_ERR, //'이전 비밀번호와 같습니다.',
  90033: '로그인이 제한된 회원입니다.',
  90034: '로그인이 제한된 학급 회원입니다.',
  90035: '탈퇴 진행 중인 회원입니다.',
  90040: '본인 인증 토큰이 유효하지 않습니다.',
  90041: '본인 인증 토큰이 만료되었습니다.',
  90042: '본인 인증 토큰과 일치하는 신상 정보가 없습니다.',
  90043: '본인 인증 정보가 없습니다.',
  90050: '검증 정보가 유효하지 않습니다.',
  90051: '검증 정보가 만료되었습니다.',
  90052: '실패 제한 횟수를 초과하였습니다.',
  90053: '이미 검증이 완료된 이메일입니다.',
  90054: EMAIL_MSG_OBJ.EMAIL_NOT_CODE,
  90055: '검증 정보가 존재하지 않습니다.',
  90056: '검증된 이메일 주소가 아닙니다.',
  90057: '등록된 이메일 주소와 일치하지 않습니다.',
  90058: '비밀번호 변경 인증 토큰이 만료되었습니다.',
  90059: '비밀번호 변경 인증 토큰이 유효하지 않습니다.',
  90060: '인증 메일 발송에 실패 하였습니다.',
  90061: '인증 코드 검증에 실패 하였습니다.',
  90070: '회원타입은 학생만 가능합니다.',
  90071: '선생님만 계정 일괄 생성이 가능합니다.',
  90072: '일치하는 선생님 정보가 없습니다.',
  90080: '로그인이 제한된 회원이 아닙니다.',
  90081: '등록된 이메일 주소가 없습니다.',
  90082:
    '선생님이 삭제하여 사용할 수 없는 계정입니다.\n이용을 원하시면 홈페이지에서 회원가입을 해주세요.',
  90090: '사용할 수 없는 아이디입니다.',
  90091: '잘못된 계정 타입입니다.',
  90092: '탈퇴 신청한 회원이 아닙니다.',
  90093: '취소 기한이 지나, 탈퇴 취소를 할수 없습니다.',
  91000: '본인인증 시작 API에서 오류가 발생하였습니다.',
  91001: '본인인증 완료 API에서 오류가 발생하였습니다.'
}

const NICKNAME_VALIDATOR: { [key: string]: Function } = {
  nicknameLength: (value: string) => /^.{2,16}$/.test(value), // 프로필 이름은 2~16자 이내여야 합니다.
  nicknameUnderbar: (value: string) => /^[^_].*[^_]$/.test(value), //영문 대소문자 + 숫자 + 특수문자 조합 가능. 5~20자
  nicknameChar: (value: string) => /^[\p{L}\p{N}_]+$/u.test(value), // 온전한 문자,숫자 및 _만 사용할 수 있습니다.
  nicknameHasHangulParents: (value: string) => /[ㄱ-ㅎㅏ-ㅣ]/gi.test(value), // 한글 자음및 모음 못씀
  nicknameSpace: (value: string) => /^\S+$/.test(value) // 프로필 이름에는 공백을 포함할 수 없습니다.
}

const PWD_VALIDATOR: { [key: string]: Function } = {
  pwdRule: (value: string) => /^[a-zA-Z0-9!@#$%^*+=-]{5,20}$/.test(value), //영문 대소문자 + 숫자 + 특수문자 조합 가능. 5~20자
  pwdSame: (value: string, value2: string) => {
    return value && value2 && value === value2
  }
}

const EMAIL_VALIDATOR: { [key: string]: Function } = {
  email: (value: string) =>
    /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value) // 이메일 검증 -> 50자 제한 /^(?=.{1,50}$)[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
}

const PROFILE_ERROR_CODE = {
  NO_PROFILE: 6052
}

const STUDENT = 'STUDENT'
const TEACHER = 'TEACHER'
const GENERAL = 'GENERAL'
const DEFAULT_PHASE = 'default'
const PWD_PHASE = 'pwd'
const EMAIL_PHASE = 'email'
const NICKNAME_PHASE = 'nickname'
const TERMS_PHASE = 'terms'
const COMPLETE_PHASE = 'complete'

const EMAIL_READY = 0 // 발송 전
const EMAIL_SENDED = 1 // 발송 후
const EMAIL_RE_READY = 2 // 재발송 전

const ACCESS_TOKEN_KEY = '_a_t'
const REFRESH_TOKEN_KEY = '_r_t'

const HELLOMAPLE_BIZ_EMAIL = 'hellomaple_biz@nexon.co.kr'

export {
  EMAIL_MSG_OBJ,
  ERR_MSG_OBJ,
  NICKNAME_MSG_OBJ,
  PWD_MSG_OBJ,
  NICKNAME_VALIDATOR,
  PWD_VALIDATOR,
  EMAIL_VALIDATOR,
  PROFILE_ERROR_CODE,
  STUDENT,
  TEACHER,
  GENERAL,
  DEFAULT_PHASE,
  PWD_PHASE,
  EMAIL_PHASE,
  NICKNAME_PHASE,
  TERMS_PHASE,
  COMPLETE_PHASE,
  EMAIL_READY,
  EMAIL_SENDED,
  EMAIL_RE_READY,
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  HELLOMAPLE_BIZ_EMAIL
}
