import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "login-not-required": () => import("/app/middleware/login-not-required.ts"),
  "login-required": () => import("/app/middleware/login-required.ts"),
  "track-route": () => import("/app/middleware/track-route.ts")
}