<template>
  <div v-if="props.isOpen" class="modal_common">
    <section class="content_modal" @click.stop>
      <atoms-c-icon
        icon-name="modal_close"
        class="btn_close"
        @click="() => emit('close')"
      />
      <!-- TODO V-IF-->
      <header v-if="$slots.title" class="header_modal">
        <slot name="title"></slot>
      </header>

      <div class="body_modal">
        <slot name="body"></slot>
      </div>

      <footer v-if="$slots.footer" class="footer_modal">
        <slot name="footer"></slot>
      </footer>
    </section>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['close'])

function onOverlayClick() {
  emit('close')
}
</script>

<style scoped lang="scss">
.modal_common {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: var(--modal-z-index);
  text-align: center;
  .content_modal {
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    padding: 32px;
    margin: 0 20px;
    min-width: 420px;
    top: 50%;
    transform: translateY(-50%);

    border-radius: 8px;
    background: #fff;
  }
  .btn_close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
  .header_modal {
    color: $black0;
    font-size: 32px;
    font-weight: 700;
    line-height: 150%; /* 48px */
    letter-spacing: -1.28px;
  }
  .body_modal {
    margin-top: 16px;
    color: $black1;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    letter-spacing: -0.8px;
    white-space: break-spaces;
    + .footer_modal {
      margin-top: 24px;
    }
  }
  .footer_modal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    height: 56px;
    font-size: 18px;
    > ::v-deep(.btn) {
      justify-content: center;
    }
  }
  @include tablet {
    .content_modal {
      padding: 20px;
      min-width: 300px;
    }
    .header_modal {
      font-size: 20px;
      letter-spacing: -0.8px;
      + .body_modal {
        margin-top: 8px;
      }
    }
    .btn_close {
      width: 20px;
      height: 20px;
    }
    .body_modal {
      font-size: 15px;
      letter-spacing: -0.6px;
    }
    .footer_modal {
      height: 48px;
      ::v-deep(.txt_btn) {
        font-size: 15px;
      }
    }
  }
}
</style>
