<template>
  <atoms-c-modal
    :is-open="isOpenConfirm"
    :class="[modal?.className, 'confirm']"
    @close="handleClickCancel"
  >
    <template v-if="modal?.title" #title>
      <h2>{{ modal?.title }}</h2>
    </template>
    <template #body>
      <p>{{ modal?.content }}</p>
    </template>
    <template #footer>
      <atoms-outlined-button
        class="btn_close"
        :text="closeText"
        @click="handleClickCancel"
      />
      <atoms-default-button
        class="btn_confirm"
        :text="confirmText"
        @click="handleClickConfirm"
      />
    </template>
  </atoms-c-modal>
</template>
<script lang="ts" setup>
const { isOpenConfirm, modal } = storeToRefs(useModalStore())
const { closeModal } = useModalStore()

const closeText = computed(() => {
  if (modal.value && modal.value.closeText) {
    return modal.value.closeText
  }
  return '취소'
})
const confirmText = computed(() => {
  if (modal.value && modal.value.confirmText) {
    return modal.value.confirmText
  }
  return '확인'
})
const handleClickCancel = () => {
  if (typeof modal.value?.fallback === 'function') {
    modal.value.fallback()
  }
  closeModal('confirm')
}
const handleClickConfirm = () => {
  if (typeof modal.value?.callback === 'function') {
    modal.value.callback()
  }
  closeModal('confirm')
}
</script>
<style lang="scss" scoped>
.confirm {
  ::v-deep(.content_modal) {
    @include desktop {
      width: 450px;
    }
    @include tablet {
      min-width: 320px;
    }
  }
  .footer_modal {
    column-gap: 8px;
    > button {
      flex: 1;
    }
    .btn_outlined {
      padding: 12px 14px;
      ::v-deep(.txt_btn) {
        @include desktop {
          font-size: 18px;
        }
      }
    }
  }
}
</style>
