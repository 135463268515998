import Vue3Toastify, { toast } from 'vue3-toastify'
import '@/assets/style/toastify.scss'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Vue3Toastify, {
    autoClose: 3000,
    // icon: false,
    position: toast.POSITION.BOTTOM_CENTER,
    limit: 3,
    theme: 'dark',
    hideProgressBar: true,
    style: {
      opacity: '0.9',
      top: '50%'
    },
    closeButton: false
  })

  return {
    provide: { toast }
  }
})
