import { $fetch } from 'ofetch'

// locals

import AccountModule from '@/api/modules/account'
import SignUpModule from '@/api/modules/signUp'
import BoardModule from '@/api/modules/board'
import BannerModule from '@/api/modules/banner'
import TermModule from '@/api/modules/terms'
import AuthModule from '@/api/modules/auth'
import ProfileModule from '@/api/modules/profile'
import ContactModule from '~/api/modules/contact'

export interface ApiModules {
  account: AccountModule
  signUp: SignUpModule
  board: BoardModule
  banner: BannerModule
  term: TermModule
  auth: AuthModule
  profile: ProfileModule
  contact: ContactModule
}

// https://medium.com/@luizzappa/nuxt-3-repository-pattern-organising-and-managing-your-calls-to-apis-with-typescript-acd563a4e046
// https://www.vuemastery.com/blog/api-management-in-nuxt-3-with-typescript/
export default defineNuxtPlugin({
  name: 'api',
  parallel: true,
  setup(nuxtApp) {
    const config = useRuntimeConfig()
    const isProdServer = process.server && process.env.NODE_ENV === 'production'
    const { accessToken } = useAuthToken()
    const fetchInstance = $fetch.create({
      headers: isProdServer ? useRequestHeaders() : undefined, // for test
      baseURL: isProdServer ? config.apiClusterURL : config.public.apiBaseURL,
      timeout: 8000,
      onRequest({ request, options }) {
        // Log request
        console.log('[fetch request]', request /* options */)
        if (options?.authorization && accessToken.value) {
          const newHeader: any = { ...options?.headers }
          newHeader.Authorization = `Bearer ${accessToken.value}`
          options.headers = newHeader
        }
      },
      onRequestError({ request, options, error }) {
        // Log error
        console.log('[fetch request error]', request /* options */)
      },
      onResponse({ request, response, options }) {
        // Log response
        console.log(
          '[fetch response]',
          request,
          response.status /* response.body */
        )
      },
      onResponseError({ request, response, options }) {
        // Log error
        console.log(
          '[fetch response error]',
          request,
          response.status,
          response.body
        )
      }
      // credentials: 'include'
    })
    const modules: ApiModules = {
      account: new AccountModule(fetchInstance),
      signUp: new SignUpModule(fetchInstance),
      board: new BoardModule(fetchInstance),
      banner: new BannerModule(fetchInstance),
      term: new TermModule(fetchInstance),
      auth: new AuthModule(fetchInstance),
      profile: new ProfileModule(fetchInstance),
      contact: new ContactModule(fetchInstance)
    }

    return {
      provide: {
        api: modules
      }
    }
  }
})
