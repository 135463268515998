<template>
  <atoms-c-modal
    :is-open="isOpenAlert"
    :class="modal?.className"
    @close="closeAlertModal"
  >
    <template v-if="modal?.title" #title>
      <h2>{{ modal?.title }}</h2>
    </template>
    <template #body>
      <p>{{ modal?.content }}</p>
    </template>
    <template #footer>
      <atoms-default-button
        class="btn_close"
        :text="closeText"
        @click="closeAlertModal"
      />
    </template>
  </atoms-c-modal>
</template>
<script lang="ts" setup>
const { isOpenAlert, modal } = storeToRefs(useModalStore())
const { closeModal } = useModalStore()

const closeText = computed(() => {
  if (modal.value && modal.value.closeText) {
    return modal.value.closeText
  }
  return '확인'
})
const closeAlertModal = () => {
  if (typeof modal.value?.callback === 'function') {
    modal.value.callback()
  }
  closeModal('alert')
}
</script>
<style lang="scss" scoped>
.alert_login_limit {
  ::v-deep(.content_modal) {
    @include desktop {
      width: 450px;
    }
  }
  .btn_close {
    flex: 1;
  }
}
.alert_delete_account {
  ::v-deep(.content_modal) {
    @include desktop {
      width: 450px;
    }
    @include tablet {
      min-width: 320px;
    }
  }
}
.btn_close {
  min-width: 180px;
  @include tablet {
    min-width: 120px;
  }
}
</style>
